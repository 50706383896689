export const formConfig = {
  id: 'form-checkout',
  cardholderName: {
    id: 'form-checkout__cardholderName',
    placeholder: 'Titular de la tarjeta'
  },
  cardholderEmail: {
    id: 'form-checkout__cardholderEmail',
    placeholder: 'E-mail'
  },
  cardNumber: {
    id: 'form-checkout__cardNumber',
    placeholder: 'Número de la tarjeta'
  },
  cardExpirationMonth: {
    id: 'form-checkout__cardExpirationMonth',
    placeholder: 'Mes de vencimiento'
  },
  cardExpirationYear: {
    id: 'form-checkout__cardExpirationYear',
    placeholder: 'Año de vencimiento'
  },
  securityCode: {
    id: 'form-checkout__securityCode',
    placeholder: 'Código de seguridad'
  },
  installments: {
    id: 'form-checkout__installments',
    placeholder: 'Cuotas'
  },
  identificationType: {
    id: 'form-checkout__identificationType',
    placeholder: 'Tipo de documento'
  },
  identificationNumber: {
    id: 'form-checkout__identificationNumber',
    placeholder: 'Número de documento'
  },
  issuer: {
    id: 'form-checkout__issuer',
    placeholder: 'Banco emisor'
  }
};
